<template>
  <metainfo>
    <template #title="{ content }">{{
      content ? `${content} | Moco Software` : `Moco Software`
    }}</template>
  </metainfo>
  <v-app class="bg-surface">
    <HeaderBar />
    <v-main>
      <vue-particles
        id="tsparticles"
        :key="key"
        :particles-init="particlesInit"
        :particles-loaded="particlesLoaded"
        :options="options()"
      />
    </v-main>
    <router-view class="mb-12" />
    <FooterBar class="glowing-text" />
  </v-app>
</template>

<script setup lang="ts">
  import { loadSlim } from 'tsparticles-slim';
  import HeaderBar from '@/components/HeaderBar.vue';
  import FooterBar from '@/components/FooterBar.vue';
  import { computed } from 'vue';
  import { useTheme } from 'vuetify';
  const theme = useTheme();

  const key = computed(() => {
    return true;
  });
  const particlesInit = async (engine: any) => {
    await loadSlim(engine);
    // console.log(engine);
  };

  const particlesLoaded = async (container: any) => {
    // console.log('Particles container loaded', container);
  };

  function options() {
    return {
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: 'push',
          },
          onHover: {
            enable: true,
            mode: 'repulse',
          },
          resize: true,
        },
        modes: {
          bubble: {
            distance: 400,
            duration: 50,
            opacity: 0.8,
            size: 40,
          },
          push: {
            quantity: 4,
          },
          repulse: {
            distance: 180,
            duration: 10,
          },
        },
      },
      particles: {
        color: {
          value: theme.current.value.colors.primary,
        },
        links: {
          color: theme.current.value.colors.textbase,
          distance: 120,
          enable: true,
          opacity: 0.5,
          width: 2,
        },
        collisions: {
          enable: false,
        },
        move: {
          direction: 'none',
          enable: true,
          outModes: {
            default: 'bounce',
          },
          random: false,
          speed: 1,
          straight: false,
        },
        number: {
          density: {
            enable: true,
            area: 1200,
          },
          value: 60,
        },
        opacity: {
          value: 0.5,
        },
        shape: {
          type: 'circle',
        },
        size: {
          value: { min: 1, max: 5 },
        },
      },
      detectRetina: true,
    };
  }
</script>
<style lang="scss">
  .glowing-text {
    filter: drop-shadow(3px 0px 4px rgba(34, 34, 34, 0.3))
      drop-shadow(-3px 0px 4px rgba(34, 34, 34, 0.3))
      drop-shadow(0px 4px 4px rgba(34, 34, 34, 0.3)) !important;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  #nav {
    padding: 30px;
    a {
      font-weight: bold;
      color: #2c3e50;
      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
  $title-font: 'Montserrat';
  .v-application {
    [class*='text-'] {
      font-family: $title-font, sans-serif !important;
    }
    .text-h3,
    .text-h2 {
      font-weight: 600 !important;
    }
  }
</style>
<style>
  @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600&family=Montserrat:wght@400;500;600;700&family=Roboto:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600&family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600&display=swap');
  * {
    font-family: 'Poppins', serif;
  }
  .Montserrat {
    font-family: 'Montserrat', serif !important;
  }
  .ss-pro {
    font-family: 'Raleway', serif !important;
    font-weight: 600 !important;
  }
</style>
