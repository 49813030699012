import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import './assets/css/app.scss';
import { BrowserTracing, init, vueRouterInstrumentation } from '@sentry/vue';
import { vuetify } from '@/plugins/vuetify';
import { registerSW } from 'virtual:pwa-register';
import Particles from 'vue3-particles';
import { createMetaManager } from 'vue-meta';

const app = createApp(App);

registerSW({ immediate: true });
init({
  app,
  dsn: 'https://b6f983b5d70f8c8194e1a7440f79afbc@o4506250729684992.ingest.sentry.io/4506397661659136',
  integrations: [
    new BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['*'],
      routingInstrumentation: vueRouterInstrumentation(router),
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(Particles);
app.use(createMetaManager());
app.use(vuetify).use(router).use(createPinia()).mount('#app');
