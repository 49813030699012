<script setup lang="ts">
  import logoLazy from '@/assets/images/moco_logo_transparent.png?w=60&format=webp&imagetools';
  import logoHd from '@/assets/images/moco_logo_transparent.png?w=220&format=webp&imagetools';
  import { ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { useDisplay } from 'vuetify';

  const { xs } = useDisplay();
  const route = useRoute();
  const routes = ref([
    { name: 'Moco', route: '/' },
    { name: 'Honey', route: '/honey' },
    { name: 'About', route: '/about' },
    { name: 'Solutions', route: '/solutions' },
    { name: 'Consulting', route: '/consulting' },
    { name: 'Pricing', route: '/pricing' },
    { name: 'Contact', route: '/contact' },
  ]);
  const drawer = ref<boolean>(false);
</script>

<template>
  <v-app-bar height="120" scroll-behavior="elevate">
    <v-container class="py-0 px-4 px-sm-0 fill-height header transparent">
      <router-link
        to="/"
        class="d-flex text-decoration-none toolbar3-min-width float-left"
      >
        <v-img
          :src="logoHd"
          :lazy-src="logoLazy"
          alt="Thai Lemongrass Busselton Logo"
          width="120px"
          height="120px"
          class="ml-2"
        />
      </router-link>

      <v-spacer />

      <div v-if="!xs" class="header-group mr-6">
        <div v-for="item in routes" :key="item.name" class="nav-link-wrapper">
          <router-link class="nav-link de-link" :to="item.route">{{
            item.name
          }}</router-link>
          <div
            v-if="route.fullPath === item.route"
            class="licence-item-bar-selected"
          ></div>
        </div>
      </div>
      <v-app-bar-nav-icon
        v-else
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </v-container>
  </v-app-bar>
  <v-navigation-drawer v-model="drawer" location="right" temporary>
    <v-list>
      <v-list-item
        v-for="item in routes"
        :key="item.name"
        class="text-h4 my-4"
        :to="item.route"
        >{{ item.name }}
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<style scoped lang="scss">
  .header {
    display: flex;
    flex-wrap: nowrap;
    flex: 1 0 67%;
    align-items: center;
  }
  .header-group {
    justify-content: flex-end;
    display: block;
    flex: 1 0 67%;

    position: relative;
    text-align: right;
    margin-left: auto;
    padding-left: 2vw;
    overflow-wrap: break-word;
  }
  .header-group > div {
    white-space: nowrap;
  }
  .nav-link-wrapper {
    transition: all 0.3s;
    position: relative;
    display: inline-block;
    margin-left: 2.2vw;
    padding-bottom: 3px;
  }
  .nav-link-wrapper:hover {
    transform: scale(1.05);
  }
  .nav-link {
    text-transform: uppercase;
    display: block;
    letter-spacing: 1.5px;
  }
  .licence-item-bar-selected {
    background: #4d698f;
    height: 2px;
    width: 100%;
    border-radius: 5px;
  }
</style>
