import { createVuetify, ThemeDefinition } from 'vuetify';
import 'vuetify/styles';
import { mdiPhone } from '@mdi/js';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';

const theme: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#f2f5f8',
    surface: '#f2f5f8',
    textbase: '#222',
    primary: '#2a83f8',
    secondary: '#03DAC6',
    error: '#ff0000',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
};
export const vuetify = createVuetify({
  icons: {
    aliases: {
      ...aliases,
    },
    defaultSet: 'mdi',
    sets: {
      mdi,
    },
  },
  theme: {
    defaultTheme: 'theme',
    themes: {
      theme,
    },
  },
});
