import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../modules/pages/HomePage.vue'),
  },
  {
    path: '/honey',
    name: 'Honey',
    component: () => import('../modules/pages/Honey.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../modules/pages/About.vue'),
  },
  {
    path: '/solutions',
    name: 'Solutions',
    component: () => import('../modules/pages/Solutions.vue'),
  },
  {
    path: '/consulting',
    name: 'Consulting',
    component: () => import('../modules/pages/Consulting.vue'),
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: () => import('../modules/pages/Pricing.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../modules/pages/Contact.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'ErrorView',
    component: () => import('../modules/pages/HomePage.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
