<template>
  <span>
    Built by
    <a
      class="text-decoration-none text-primary"
      href="https://moco.software"
      target="blank"
      >Moco Software</a
    >
  </span>
</template>
