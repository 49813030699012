import { useDisplay } from 'vuetify';
import { computed } from 'vue';

export function call(url: string) {
  window.open(url, '_blank');
}

export const isSmall = computed<boolean>(() => {
  const { name } = useDisplay();

  switch (name.value) {
    case 'xs':
    case 'sm':
      return true;
    case 'md':
    case 'lg':
    case 'xl':
    case 'xxl':
      return false;
  }

  return true;
});
