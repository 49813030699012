<script setup lang="ts">
  import CopyrightMoco from '@/components/CopyrightMoco.vue';
  import Copyright from '@/components/Copyright.vue';
  import { onMounted, ref } from 'vue';
  import { mdiGoogle } from '@mdi/js';
  import FooterDisplay from '@/components/FooterDisplay.vue';

  const icons = ref([
    {
      link: 'https://www.google.com/search?q=moco+software',
      icon: mdiGoogle,
      alt: 'google link',
    },
  ]);

  function openLink(link: string) {
    window.open(link, '_blank');
  }

  const showFooter = ref<boolean>(false);

  onMounted(() => {
    setTimeout(() => {
      showFooter.value = true;
    }, 500);
  });
</script>

<template>
  <v-footer
    v-if="showFooter"
    :absolute="true"
    class="text-center d-flex flex-column footer-bar"
    height="auto"
    width="100vw"
  >
    <v-container class="py-2 mb-4">
      <div>
        <v-btn
          v-for="icon in icons"
          :key="icon.icon"
          class="mx-2"
          :icon="icon.icon"
          :aria-label="icon.alt"
          role="button"
          variant="text"
          @click="openLink(icon.link)"
        ></v-btn>
      </div>

      <FooterDisplay />

      <v-divider class="my-8"></v-divider>
      <div class="text-center caption pb-2 font-weight-light">
        <Copyright />
      </div>
      <div class="text-center caption font-weight-medium">
        <CopyrightMoco />
      </div>
    </v-container>
  </v-footer>
</template>

<style scoped lang="scss">
  $title-font: 'Roboto';
  .v-application {
    .footer-bar {
      [class*='text-'] {
        font-family: $title-font, sans-serif !important;
      }
    }
  }
</style>
