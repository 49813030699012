<script setup lang="ts">
  import { call } from '@/utils';
  import { useDisplay } from 'vuetify';
  import { computed } from 'vue';

  import { mdiPhone, mdiEmail, mdiMapMarker } from '@mdi/js';

  const { name } = useDisplay();

  const isSmall = computed<boolean>(() => {
    switch (name.value) {
      case 'xs':
      case 'sm':
        return true;
      case 'md':
      case 'lg':
      case 'xl':
      case 'xxl':
        return false;
    }

    return true;
  });
</script>

<template>
  <v-row>
    <v-col cols="12" md="6" :class="isSmall ? 'text-center' : 'text-left'">
      <div class="font-weight-black text-h6">CONTACT INFORMATION</div>
      <v-divider class="my-4" />
      <v-icon :icon="mdiMapMarker" />
      <v-btn
        :flat="true"
        class="de-link"
        @click="call('https://goo.gl/maps/HkyWFzqJMMMTm7nH6')"
      >
        <span class="Montserrat text-capitalize squash"
          >61 Sherwood Crescent, Broadwater, WA 6280</span
        >
      </v-btn>
      <br />
      <v-icon :icon="mdiPhone" />
      <v-btn :flat="true" class="de-link" @click="call('tel:61430831967')">
        <span class="Montserrat squash">+61 430 831 967</span>
      </v-btn>
      |
      <v-btn :flat="true" class="de-link" @click="call('tel:61432620324')">
        <span class="Montserrat squash">+61 432 620 324</span>
      </v-btn>
      <br />
      <v-icon :icon="mdiEmail" />
      <v-btn :flat="true" class="" @click="call('mailto:info@moco.software')">
        <span class="Montserrat text-lowercase squash">info@moco.software</span>
      </v-btn>
    </v-col>
    <v-col
      cols="12"
      md="6"
      class="text-left"
      :class="isSmall ? 'text-center' : 'text-right'"
    >
      <div class="font-weight-black text-h6">MOLLOY | COOPER</div>
      <v-divider class="my-4" />

      <span class="Montserrat squash">MOCO SOFTWARE | MOCO HONEY</span>
      <br />
      <span class="squash">
        Software Development, AI/Machine Learning Implementation and Cyber
        Infrastructure
      </span>
    </v-col>
  </v-row>
</template>

<style scoped lang="scss">
  .squash {
    letter-spacing: -0.4px;
  }
</style>
